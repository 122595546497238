import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();
  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-extrabold text-gray-900">
          {t("Home.Welcome")}
        </h1>
        <p className="mt-4 text-xl text-gray-700">{t("Home.Subtitle")}</p>
      </header>

      {/* Main Content */}
      <section className="mb-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10">
          {/* TaggedRecords */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">
              {t("CreateRecord.Title")}
            </h2>
            <p className="text-gray-600 mb-4">{t("CreateRecord.Text")}</p>
            <Link
              to="/dictionary/Travel"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("Travel")}
            </Link>
          </div>

          {/* Request Translation Section */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">
              {t("RequestTranslation.Title")}
            </h2>
            <p className="text-gray-600 mb-4">{t("RequestTranslation.Text")}</p>
            <Link
              to="/request-translation"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("RequestTranslation.Button")}
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8">
          {/* Dictionary Section */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h2 className="text-2xl font-bold text-gray-900 mb-3">
              {t("Dictionary")}
            </h2>
            <p className="text-gray-600 mb-4">{t("Home.Dictionary.Text")}</p>
            <Link
              to="/dictionary"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("Home.Dictionary.Button")}
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10">
          {/* Create Record Section */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">
              {t("CreateRecord.Title")}
            </h2>
            <p className="text-gray-600 mb-4">{t("CreateRecord.Text")}</p>
            <Link
              to="/create-record"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("CreateRecord.Button")}
            </Link>
          </div>

          {/* Request Translation Section */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h2 className="text-xl font-semibold text-gray-900 mb-3">
              {t("RequestTranslation.Title")}
            </h2>
            <p className="text-gray-600 mb-4">{t("RequestTranslation.Text")}</p>
            <Link
              to="/request"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("RequestTranslation.Button")}
            </Link>
          </div>
        </div>
      </section>

      {/* More Section */}
      <section className="mb-16">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">
            {t("Home.MoreInfo.Title")}
          </h2>
          <p className="mt-4 text-lg text-gray-600">
            {t("Home.MoreInfo.Text")}
          </p>
        </div>

        <div className="grid grid-cols-1 gap-8">
          {/* About Us Section */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h3 className="text-2xl font-semibold text-gray-900 mb-3">
              {t("AboutUs.Title")}
            </h3>
            <p className="text-gray-600 mb-4">{t("AboutUs.Text")}</p>
            <Link
              to="/about-us"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("AboutUs.Title")}
            </Link>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10">
          {/* FAQ Section */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h3 className="text-xl font-semibold text-gray-900 mb-3">
              {t("FAQ.Title")}
            </h3>
            <p className="text-gray-600 mb-4">{t("Home.FAQ.Text")}</p>
            <Link
              to="/faq"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("Home.FAQ.Button")}
            </Link>
          </div>

          {/* Blog Section */}
          <div className="bg-white p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300">
            <h3 className="text-xl font-semibold text-gray-900 mb-3">
              {t("Blog")}
            </h3>
            <p className="text-gray-600 mb-4">{t("Home.Blog.Text")}</p>
            <Link
              to="/blog"
              className="block text-center bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300"
            >
              {t("Home.Blog.Button")}
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
