import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import Spinner from "../components/Spinner";
import { FaCopy, FaTags } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export default function TaggedRecordsPage({ tag }) {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [showTags, setShowTags] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchRecords() {
      try {
        const recordRef = collection(db, "records");
        const q = query(recordRef, where("status", "==", "Accepted"));
        const querySnap = await getDocs(q);
        const recordsData = [];
        querySnap.forEach((doc) => {
          const data = doc.data();
          if (data.tags && data.tags.includes(tag)) {
            recordsData.push({
              id: doc.id,
              german: data.germanWord,
              translation: data.translation,
              phoneticSpelling: data.phoneticSpelling,
              tags: data.tags || [],
              article: data.article,
            });
          }
        });
        setRecords(recordsData);
        setFilteredRecords(recordsData);
        setLoading(false);
      } catch (error) {
        toast.error(t("Toast.FetchListing.Error"));
      }
    }

    fetchRecords();
  }, [tag, t]);

  const copyToClipboard = (value) => {
    navigator.clipboard.writeText(value);
    toast.success(t("Toast.CopyCell.Success"));
  };

  return (
    <div className="max-w-6xl mx-auto px-3 flex flex-col items-center justify-center">
      <h1 className="text-3xl text-center mt-6 font-bold mb-6">{tag}</h1>
      {loading ? (
        <Spinner />
      ) : filteredRecords && filteredRecords.length > 0 ? (
        <div className="w-full overflow-x-auto mb-20">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-gray-300 px-4 py-2">
                  {t("German")}
                </th>
                <th className="border border-gray-300 px-4 py-2">
                  {t("Malagasy")}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredRecords.map((record) => (
                <tr key={record.id}>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      {record.article
                        ? `${record.german} (${record.article})`
                        : record.german}
                      <span
                        onClick={() => copyToClipboard(record.german)}
                        className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                      >
                        <FaCopy />
                      </span>
                    </div>
                  </td>
                  <td className="border border-gray-300 px-4 py-2">
                    <div className="flex justify-between items-center">
                      <span className="flex items-center">
                        {record.phoneticSpelling
                          ? `${record.translation} [${record.phoneticSpelling}]`
                          : record.translation}
                        {record.tags.length > 0 && (
                          <span className="ml-1">
                            <FaTags
                              onClick={() => setShowTags(!showTags)}
                              className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                            />
                            {showTags && (
                              <div className="ml-1">
                                {record.tags.map((tag, index) => (
                                  <span
                                    key={index}
                                    className="inline-block bg-blue-100 px-2 rounded-full text-sm font-medium mr-1"
                                  >
                                    {t(`Tags.${tag}`)}
                                  </span>
                                ))}
                              </div>
                            )}
                          </span>
                        )}
                      </span>
                      <span
                        onClick={() => copyToClipboard(record.translation)}
                        className="cursor-pointer text-gray-500 hover:text-gray-600 transition duration-150 ease-in-out"
                      >
                        <FaCopy />
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>{t("NoRecordsFound")}</p>
      )}
    </div>
  );
}
