import { useParams } from "react-router-dom";
import TaggedRecordsPage from "../pages/TaggedRecordsPage";

function TaggedRecordsPageWrapper() {
  const { tag } = useParams();

  return <TaggedRecordsPage tag={tag} />;
}

export default TaggedRecordsPageWrapper;
